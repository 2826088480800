import React, { Component } from 'react' 
import { Link } from 'gatsby';

import { Menu, Dropdown, Button } from 'antd';
import 'antd/lib/style/core/motion.less'
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/button/style/index.css';

import Logo from "../../assets/logo/SleighLogo-Black.png"

import './Navbar.css'

export default class Navbar extends Component {

  constructor(props) {
    super(props)

    this.state = { 
        width: 0, 
        dropdownPlacement: "bottomRight"
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

}

componentDidMount() {
    this.updateWindowDimensions();

    // Listens for window resize...
    window.addEventListener('resize', this.updateWindowDimensions);
}

componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
}

/**
 * Handle window resize trigger
 */
updateWindowDimensions() {
    this.setState({ width: window.innerWidth});

    // Update contet and styles based on screen width
    if(window.innerWidth < 695) {
        this.setState({
          dropdownPlacement: "topRight"
        })
    } else {
        this.setState({
          dropdownPlacement: "bottomRight"
            
        })
    }
}

    render() {
      const inquiryMenu = (
        <Menu>
          <Menu.Item>
            <a href={"mailto: filmfund@sleigh.group?subject=Film Fund Inquiry"}>Film Fund</a>
          </Menu.Item>
          <Menu.Item>
            <a href={"mailto: originalcontent@sleigh.group?subject=Original Content Inquiry"}>Original Content</a>
          </Menu.Item>
          <Menu.Item>
            <Link to="/inquire">Localization</Link>
          </Menu.Item>
        </Menu>
      );
      
        return(
            <div id="navbar">
              <div id="logo-wrapper">
                <Link to="/">
                  <img id="logo" src={`${Logo}`} alt="Sleigh Group" />
                </Link>
              </div>
              
              <nav id="main-nav">
                <ul>
                  <li className="home-nav-item">
                    <Link exact to={"/"} activeClassName="active-link">Home</Link>
                  </li>
                  <li className="about-nav-item">
                    <Link to={"/about-us"} activeClassName="active-link">About us</Link>
                  </li>
                  <li className="team-nav-item">
                    <Link to={"/the-team"} activeClassName="active-link">The Team</Link>
                  </li>
                  {/* <li className="services-nav-item">
                    <Link to={"/services"} activeClassName="active-link">Services</Link>
                  </li> */}
                  <li className="film-fund-nav-item">
                    <Link to={"/film-fund"} activeClassName="active-link">Film Fund</Link>
                  </li>
                  <li className="journal-nav-item">
                    <Link to={"/journal"} activeClassName="active-link">Journal</Link>
                  </li>
                </ul>
              </nav>
              <div className="inquire-btn btn-container"> 
                  <Dropdown overlay={inquiryMenu} placement={this.state.dropdownPlacement} arrow>
                    <Button id='inquire-dropdown-btn'>Inquire</Button>
                  </Dropdown>
              </div>
            </div>
        )
    }
}