/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from './Navbar/Navbar'

import "./layout.css"
import "../App.css"


const Layout = ({ children }) => {

  return (
    <>
      <Navbar />
      <div>
        <main>{children}</main>
        <footer >
            <p><b>Contact us</b> at <a style={{color: 'white', textDecoration: 'underline'}} href={`mailto:contact@sleigh.group`}>contact@sleigh.group</a></p>
            <p>&copy; 2022 Sleigh Group – All rights reserved</p>
          </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
